import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../store/store";
import {ROLES} from "../../../constants/roles";
import {IRouter} from "../../app/router/router";
import withRouter from "../../app/router/withRouter";
import {Box, IconButton, Typography} from "@mui/material";
import Text from "../../app/text/text";
import {IAppNotification} from "../../../models/appNotification";
import {setAppNotification} from "../../../store/actions/app";
import dayjs from "dayjs";
import {Announcement, IMPORTANCE} from "../../../models/announcement";
import {logException} from "../../../controllers/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {getAnnouncements, getImportanceColor} from "../../../controllers/announcement";
import {
    DataGrid,
    GridCallbackDetails,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    MuiEvent
} from "@mui/x-data-grid";
import {getMUILocale, localize} from "../../../helpers/localization";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import AnnouncementDialog from "./components/dialog";

const ALLOWED_ROLES: ROLES[] = [
    ROLES.Administrator
]

type IState = {
    announcements: Announcement[],
    loading: boolean
}

type IProps = {
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings.lang,
        userRole: state.user.role,
        dateFormat: state.settings.dateFormat
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps & IRouter;

class Announcements extends React.Component<ReduxType> {
    public readonly state: IState = {
        announcements: [],
        loading: false
    }

    private editDialogRef: RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.editDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    componentDidMount() {
        if (!this.isAllowed()) {
            window.setTimeout(() => {
                this.props.router.navigate('/');
            }, 100);
            return;
        }
        this.loadData();
    }

    isAllowed(): boolean {
        return ALLOWED_ROLES.indexOf(this.props.userRole as ROLES) !== -1;
    }

    loadData() {
        this.setState((state: IState) => {
            return {...state, loading: true};
        }, () => {
            getAnnouncements().then((announcements: Announcement[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        loading: false,
                        announcements
                    };
                });
            });
        });
    }

    getColumns(): (GridColDef | GridActionsColDef)[] {
        return [
            {
                field: 'title',
                headerName: localize('Title', this.props.lang),
                minWidth: 100,
                flex: 1
            },
            {
                field: 'message',
                headerName: localize('Message', this.props.lang),
                minWidth: 100,
                flex: 2
            },
            {
                field: 'expiryDate',
                headerName: localize('Expiry', this.props.lang),
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams): React.ReactNode => {
                    if (!params.value || !dayjs(params.value).isValid()) return '';
                    return (
                        dayjs(params.value).format(this.props.dateFormat)
                    );
                }
            }
        ];
    }

    onAnnouncementSave(announcement?: Announcement) {
        this.loadData();
    }

    createAnnouncement() {
        this.editDialogRef.current.openDialog(new Announcement());
    }

    onRowClick(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) {
        this.editDialogRef.current.openDialog(params.row as Announcement);
    }

    render() {
        if (!this.isAllowed()) return null;
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} height={'100%'}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant={'h4'}>
                                <Text>Announcements</Text>
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton color={'primary'} size={'large'} onClick={this.createAnnouncement.bind(this)}>
                                <AddCircleIcon sx={{fontSize: '2rem'}}/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        '& .importance.none': {backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette[getImportanceColor(IMPORTANCE.None)].dark : theme.palette[getImportanceColor(IMPORTANCE.None)].light},
                        '& .importance.low': {backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette[getImportanceColor(IMPORTANCE.Low)].dark : theme.palette[getImportanceColor(IMPORTANCE.Low)].light},
                        '& .importance.medium': {backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette[getImportanceColor(IMPORTANCE.Medium)].dark : theme.palette[getImportanceColor(IMPORTANCE.Medium)].light},
                        '& .importance.high': {backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette[getImportanceColor(IMPORTANCE.High)].dark : theme.palette[getImportanceColor(IMPORTANCE.High)].light},
                    }}>
                        <DataGrid
                            columns={this.getColumns()}
                            rows={this.state.announcements}
                            getRowId={x => x._id}
                            loading={this.state.loading}
                            localeText={getMUILocale(this.props.lang).components.MuiDataGrid.defaultProps.localeText}
                            onRowClick={this.onRowClick.bind(this)}
                            getRowClassName={(params) => {
                                switch(params.row.importance) {
                                    case IMPORTANCE.Low:
                                        return "importance low";
                                    case IMPORTANCE.Medium:
                                        return "importance medium";
                                    case IMPORTANCE.High:
                                        return "importance high";
                                    case IMPORTANCE.None:
                                    default:
                                        return "importance none";
                                }
                            }}
                        />
                        <AnnouncementDialog
                            ref={this.editDialogRef}
                            onSave={this.onAnnouncementSave.bind(this)}
                        />
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Announcements));
